import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Checkbox, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

import AddLine from '../custom/add-line'
import TooltipTitle from '../custom/tooltip-title'

const SaveVariablesNodeSettings = ({ toast, node, update }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [values, setValues] = useState([])

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setValues(node?.data?.values || [])
			setId(node?.id)
		}
	}, [id, node])

	const add = idx => {
		setValues(vals => {
			vals.splice(idx, 0, { source: '', destination: '', saveGlobally: false })
			return [...vals]
		})
	}

	const edit = (idx, key, value) => {
		setValues(vals => [...vals.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
	}

	const remove = idx => {
		setValues(vals => [...vals.filter((_, i) => i !== idx)])
	}

	const save = useCallback(() => {
		for (const value of values) {
			if (!value.source) {
				toast('Пожалуйста введите исходное значение', { type: 'error' })
				return
			}

			if (!value.destination) {
				toast('Пожалуйста введите значение назначения', { type: 'error' })
				return
			}
		}

		update({ ...node, data: { ...node.data, description, values } })
	}, [description, node, toast, update, values])

	return (
		<Box sx={styles.main}>
			<Box sx={styles.buttons}>
				<Button variant='contained' color='primary' onClick={save}>Сохранить</Button>
			</Box>
			<Box sx={styles.container}>
				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Определение компонента</Typography>
				</Box>
				<Box sx={styles.row}>
					<TextField
						fullWidth
						multiline
						rows={3}
						placeholder='Описание'
						value={description}
						onChange={e => setDescription(e.target.value)}
					/>
				</Box>

				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Значения</Typography>
				</Box>
				<Box sx={styles.row}>
					<AddLine onClick={() => { add(0) }} help='Добавить значение' />
				</Box>
				{values.map((value, key) => <Value key={key} idx={key} {...value} add={add} edit={edit} remove={remove} />)}
			</Box>
		</Box>
	)
}

const Value = ({ idx, source, destination, saveGlobally, add, edit, remove }) => (
	<Box sx={styles.column}>
		<Box sx={styles.row}>
			<Box sx={styles.checkbox}>
				<Checkbox color='primary' checked={!!saveGlobally} onChange={e => edit(idx, 'saveGlobally', e.target.checked)} />
				<Typography sx={{ ml: 1 }} variant='body1'>Глобальное сохранение</Typography>
			</Box>
			<Box sx={styles.source}>
				<TextField
					fullWidth
					placeholder={`Исходное значение. Пример: this.moment(this.time).format('HH:mm')`}
					value={source}
					onChange={e => edit(idx, 'source', e.target.value)}
				/>
			</Box>
			<Box sx={styles.source}>
				<TextField
					fullWidth
					placeholder='Значение назначения. Пример: time'
					value={destination}
					onChange={e => edit(idx, 'destination', e.target.value)}
				/>
			</Box>
			<Box sx={styles.icon}>
				<Tooltip title={<TooltipTitle title='Удалить' />} arrow>
					<IconButton aria-label='close' sx={styles.delete} onClick={() => remove(idx)}>
						<Close />
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
		<Box sx={styles.row}>
			<AddLine onClick={() => { add(idx + 1) }} help='Добавить значение' />
		</Box>
	</Box>
)


const styles = {
	main: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	buttons: {
		width: '100%',
		display: 'flex',
		justifyContent: 'end',
		mb: 3
	},
	container: {
		px: 2,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflowX: 'hidden',
		overflowY: 'scroll'
	},
	rowTitle: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		mt: 3,
		mb: 2
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	source: {
		width: 'calc(50% - 140px)',
		pr: 4
	},
	icon: {
		width: 40
	},
	column: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	delete: {
		color: theme => theme.palette.error.main
	},
	checkbox: {
		display: 'flex',
		mr: 2,
		alignItems: 'center'
	}
}

export default SaveVariablesNodeSettings