import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material'

import AddLine from '../custom/add-line'
import Dropdown from '../custom/dropdown'
import TooltipTitle from '../custom/tooltip-title'
import { Close } from '@mui/icons-material'

const CheckParamsNodeSettings = ({ toast, node, start, update }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [params, setParams] = useState([])
	const [savedParams, setSavedParams] = useState([])

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setParams(node?.data?.params || [])
			setId(node?.id)
		}
	}, [id, node])

	useEffect(() => {
		const result = []
		start?.data?.funcs?.forEach(func => {
			func?.properties?.forEach(prop => {
				if (!result.includes(prop.name))
					result.push(prop.name)
			})
		})

		setSavedParams(result)
	}, [start])

	const edit = (idx, key, value) => {
		setParams(prms => [...prms.map((p, i) => ({ ...p, [key]: i === idx ? value : p[key] }))])
	}

	const remove = idx => {
		setParams(prms => [...prms.filter((_, i) => i !== idx)])
	}

	const save = useCallback(() => {
		for (const param of params) {
			if (!param.name) {
				toast('Пожалуйста выберите переменную', { type: 'error' })
				return
			}

			if (!param.value) {
				toast('Пожалуйста введите текст для переменной', { type: 'error' })
				return
			}
		}

		update({ ...node, data: { ...node.data, description, params } })
	}, [description, node, params, toast, update])


	return (
		<Box sx={styles.main}>
			<Box sx={styles.buttons}>
				<Button variant='contained' color='primary' onClick={save}>Сохранить</Button>
			</Box>
			<Box sx={styles.container}>
				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Определение компонента</Typography>
				</Box>
				<Box sx={styles.row}>
					<TextField
						fullWidth
						multiline
						rows={3}
						placeholder='Описание'
						value={description}
						onChange={e => setDescription(e.target.value)}
					/>
				</Box>

				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Переменные</Typography>
				</Box>
				{
					params.map((param, key) => (
						<Parameter
							key={key}
							idx={key}
							{...param}
							params={savedParams}
							edit={edit}
							remove={remove}
						/>
					))
				}
				<Box sx={styles.row}>
					<AddLine onClick={() => { setParams([...params, { name: '', value: '' }]) }} help='Добавить переменную' />
				</Box>
			</Box>
		</Box>
	)
}

const Parameter = ({ idx, name, value, params, edit, remove }) => (
	<Box sx={styles.row}>
		<Box sx={styles.dropdown}>
			<Dropdown selected={name} items={params.map(p => ({ id: p, name: p }))} onChange={it => edit(idx, 'name', it)} />
		</Box>
		<Box sx={styles.textfield}>
			<TextField
				fullWidth
				multiline
				rows={3}
				placeholder='Вы хотите записаться на групповое занятие или на персональную тренировку?'
				value={value}
				onChange={e => edit(idx, 'value', e.target.value)}
			/>
		</Box>
		<Box sx={styles.icon}>
			<Tooltip title={<TooltipTitle title='Удалить' />} arrow>
				<IconButton aria-label='close' sx={styles.delete} onClick={() => remove(idx)}>
					<Close />
				</IconButton>
			</Tooltip>
		</Box>
	</Box>
)

const styles = {
	main: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	buttons: {
		width: '100%',
		display: 'flex',
		justifyContent: 'end',
		mb: 3
	},
	container: {
		px: 2,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflowX: 'hidden',
		overflowY: 'scroll'
	},
	rowTitle: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		mt: 3,
		mb: 2
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	dropdown: {
		width: 'calc(30% - 16px)'
	},
	textfield: {
		width: 'calc(60% - 56px)'
	},
	icon: {
		width: 40
	},
	delete: {
		color: theme => theme.palette.error.main
	}
}

export default CheckParamsNodeSettings