import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import Dropdown from '../custom/dropdown'
import { conditionTypes, propTypes } from '../../helpers/constants'
import AddLine from '../custom/add-line'
import TooltipTitle from '../custom/tooltip-title'

const ConditionDialog = ({ condition, webhooks, toast, onClose, save }) => {
	const [name, setName] = useState('')
	const [webhookId, setWebhookId] = useState('')
	const [conditionWebhookId, setConditionWebhookId] = useState('')
	const [type, setType] = useState('update_auth_header')
	const [mappingRules, setMappingRules] = useState([])

	useEffect(() => {
		setName(condition?.name || '')
		setWebhookId(condition?.webhookId || '')
		setConditionWebhookId(condition?.conditionWebhookId || '')
		setType(condition?.type || 'update_auth_header')
		setMappingRules(condition?.mappingRules || [])
	}, [condition])

	const add = idx => {
		setMappingRules(mrs => {
			mrs.splice(idx, 0, { nullable: false, sourcePath: '', sourceType: 'string', destinationPath: '' })
			return [...mrs]
		})
	}

	const edit = (idx, key, value) => {
		setMappingRules(mrs => [...mrs.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
	}

	const remove = idx => {
		setMappingRules(mrs => [...mrs.filter((_, i) => i !== idx)])
	}

	const onSave = useCallback(() => {
		if (!name) {
			toast('Пожалуйста введите название', { type: 'error' })
			return
		}

		if (!webhooks.find(webhook => webhook.id === webhookId) || !webhooks.find(webhook => webhook.id === conditionWebhookId)) {
			toast('Пожалуйста выберите вебхук', { type: 'error' })
			return
		}

		save({
			name,
			webhookId,
			conditionWebhookId,
			type,
			mappingRules
		})
	}, [conditionWebhookId, mappingRules, name, save, toast, type, webhookId, webhooks])

	return <Dialog open maxWidth='lg' fullWidth={true}>
		<DialogTitle sx={styles.title}>
			<Typography variant='h4' fontWeight='bold'>{condition ? 'Изменение настроек' : 'Добавление'} условия</Typography>
			<IconButton aria-label='close' onClick={onClose} sx={styles.closeIcon}>
				<Close />
			</IconButton>
		</DialogTitle>
		<DialogContent dividers>
			<Box sx={styles.body}>
				<Box sx={styles.row}>
				<TextField fullWidth placeholder='Название' value={name} onChange={e => setName(e.target.value)} />
				</Box>
				<Box sx={styles.row}>
					<Box sx={styles.custom33}>
						<Dropdown placeholder='webhookId' selected={webhookId} items={webhooks.map(w => ({ id: w.id, name: w.name }))} onChange={item => setWebhookId(item)} />
					</Box>
					<Box sx={styles.custom33}>
						<Dropdown placeholder='conditionWebhookId' selected={conditionWebhookId} items={webhooks.map(w => ({ id: w.id, name: w.name }))} onChange={item => setConditionWebhookId(item)} />
					</Box>
					<Box sx={styles.custom33}>
						<Dropdown selected={type} items={conditionTypes} onChange={item => setType(item)} />
					</Box>
				</Box>

				<Items title='Правила отображения (Mapping rules)' items={mappingRules} add={add} edit={edit} remove={remove} />
			</Box>
		</DialogContent>
		<DialogActions>
			<Button variant='contained' onClick={onSave}>{condition ? 'Сохранить' : 'Добавить'}</Button>
		</DialogActions>
	</Dialog>
}

const Items = ({ title, items, add, edit, remove }) => (
	<Box sx={styles.column}>
		<Box sx={styles.rowTitle}>
			<Typography variant='h4' fontWeight='bold'>{title}</Typography>
		</Box>
		<AddLine onClick={() => { add(0) }} help='Добавить' />
		<Box sx={styles.column}>
			{
				items.map((item, key) => (
					<Item key={key} item={item} add={() => { add(key + 1) }} edit={(k, v) => { edit(key, k, v) }} remove={() => { remove(key) }} />
				))
			}
		</Box>
	</Box>
)

const Item = ({ item, add, edit, remove }) => (
	<Box sx={styles.column}>
		<Box sx={styles.row}>
			<Box sx={styles.custom25}>
				<Checkbox checked={!!item.nullable} onChange={e => edit('nullable', e.target.checked)} />
				<Typography sx={styles.ml1} variant='body1'>Nullable</Typography>
			</Box>
			<Box sx={styles.custom25}>
				<TextField fullWidth placeholder='Исходное значение' value={item.sourcePath} onChange={e => edit('sourcePath', e.target.value)} />
			</Box>
			<Box sx={styles.custom25}>
				<Dropdown selected={item.sourceType} items={propTypes} onChange={item => edit('sourceType', item)} />
			</Box>
			<Box sx={styles.custom25}>
				<TextField fullWidth placeholder='Значение для сохранения' value={item.destinationPath} onChange={e => edit('destinationPath', e.target.value)} />
			</Box>
			<Box sx={styles.icon}>
				<Tooltip title={<TooltipTitle title='Удалить' />} arrow>
					<IconButton aria-label='close' sx={styles.delete} onClick={remove}>
						<Close />
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
		<Box sx={styles.row}>
			<AddLine onClick={add} help='Добавить' />
		</Box>
	</Box>
)

const styles = {
	title: {
		m: 0,
		p: 2,
		fontWeight: 900
	},
	closeIcon: {
		position: 'absolute',
		right: 8,
		top: 8,
		color: theme => theme.palette.text.secondary
	},
	body: {
		my: 1,
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		mb: 2
	},
	custom25: {
		width: 'calc(25% - 26px)',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	custom33: {
		width: 'calc(33% - 29px)',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	rowTitle: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		mt: 3,
		mb: 2
	},
	icon: {
		width: 40,
		display: 'flex',
		alignItems: 'center',
	},
	delete: {
		color: theme => theme.palette.error.main
	},
	ml1: {
		ml: 1
	}
}

export default ConditionDialog