import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'

const ResultNodeSettings = ({ toast, node, update }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [result, setResult] = useState('')

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setResult(node?.data?.result || '')
			setId(node?.id)
		}
	}, [id, node])

	const save = useCallback(() => {
		if (!result) {
			toast('Пожалуйста введите текст для ответа', { type: 'error' })
			return
		}

		update({ ...node, data: { ...node.data, description, result } })
	}, [description, node, result, toast, update])

	return (
		<Box sx={styles.main}>
			<Box sx={styles.buttons}>
				<Button variant='contained' color='primary' onClick={save}>Сохранить</Button>
			</Box>

			<Box sx={styles.container}>
				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Определение компонента</Typography>
				</Box>
				<Box sx={styles.row}>
					<TextField
						fullWidth
						multiline
						rows={3}
						placeholder='Описание'
						value={description}
						onChange={e => setDescription(e.target.value)}
					/>
				</Box>

				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Текст для ответа</Typography>
				</Box>
				<Box sx={styles.row}>
					<TextField
						fullWidth
						multiline
						rows={5}
						placeholder={`'В какой день и время вы хотите записаться к тренеру "' + this.instructor + '" на занятие "' + this.lesson + '"?'`}
						value={result}
						onChange={e => setResult(e.target.value)}
					/>
				</Box>
			</Box>
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	buttons: {
		width: '100%',
		display: 'flex',
		justifyContent: 'end',
		mb: 3
	},
	container: {
		px: 2,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflowX: 'hidden',
		overflowY: 'scroll'
	},
	rowTitle: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		mt: 3,
		mb: 2
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	name: {
		width: 'calc(30% - 16px)'
	},
	condition: {
		width: 'calc(60% - 56px)'
	},
	icon: {
		width: 40
	},
	column: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	delete: {
		color: theme => theme.palette.error.main
	}
}

export default ResultNodeSettings