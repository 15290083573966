import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Checkbox, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { v4 as uuidv4 } from 'uuid'

import AddLine from '../custom/add-line'
import Dropdown from '../custom/dropdown'
import { propTypes, roles } from '../../helpers/constants'
import TooltipTitle from '../custom/tooltip-title'

const StartNodeSettings = ({ toast, node, update }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [promts, setPromts] = useState([])
	const [funcs, setFuncs] = useState([])

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setPromts(node?.data?.promts || [])
			setFuncs(node?.data?.funcs || [])
			setId(node?.id)
		}
	}, [id, node])

	const addPromt = idx => {
		setPromts(pts => {
			pts.splice(idx, 0, { role: 'system', content: '' })
			return [...pts]
		})
	}

	const editPromt = (idx, key, value) => {
		setPromts(pts => [...pts.map((p, i) => ({ ...p, [key]: i === idx ? value : p[key] }))])
	}

	const deletePromt = idx => {
		setPromts(pts => [...pts.filter((_, i) => i !== idx)])
	}

	const addFunc = idx => {
		setFuncs(fns => {
			fns.splice(idx, 0, {
				id: uuidv4(),
				name: '',
				description: '',
				properties: []
			})
			return [...fns]
		})
	}

	const editFunc = (idx, key, value) => {
		setFuncs(fns => [...fns.map((f, i) => ({ ...f, [key]: i === idx ? value : f[key] }))])
	}

	const deleteFunc = idx => {
		setFuncs(fns => [...fns.filter((_, i) => i !== idx)])
	}

	const addProperty = (funcIdx, idx) => {
		setFuncs(fns => {
			fns[funcIdx].properties.splice(idx, 0, {
				name: '',
				type: 'string',
				required: false,
				format: '',
				example: '',
				description: ''
			})
			return [...fns]
		})
	}

	const editProperty = (funcIdx, idx, key, value) => {
		setFuncs(fns => [...fns.map((f, fi) => ({
			...f,
			properties: [...f.properties.map((p, pi) => ({
				...p,
				[key]: funcIdx === fi && idx === pi ? value : p[key]
			}))]
		}))])
	}

	const deleteProperty = (funcIdx, idx) => {
		setFuncs(fns => [...fns.map((f, fi) => ({
			...f,
			properties: [...f.properties.filter((_, pi) => !(funcIdx === fi && idx === pi))]
		}))])
	}

	const save = useCallback(() => {
		for (const promt of promts) {
			if (promt.content.length === 0) {
				toast('Промпт не может быть пустым', { type: 'error' })
				return
			}
		}

		for (const func of funcs) {
			if (func.description.length === 0) {
				toast('Описание функции не может быть пустым', { type: 'error' })
				return
			}

			for (const property of func.properties) {
				if (property.name.length === 0) {
					toast('Название переменной не может быть пустым', { type: 'error' })
					return
				}

				if (property.example.length === 0) {
					toast('Пример переменной не может быть пустым', { type: 'error' })
					return
				}

				if (property.description.length === 0) {
					toast('Описание переменной не может быть пустым', { type: 'error' })
					return
				}
			}
		}

		update({ ...node, data: { ...node.data, description, promts, funcs } })
	}, [description, funcs, node, promts, toast, update])

	return (
		<Box sx={styles.main}>
			<Box sx={styles.buttons}>
				<Button variant='contained' color='primary' onClick={save}>Сохранить</Button>
			</Box>
			<Box sx={styles.container}>
				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Определение компонента</Typography>
				</Box>
				<Box sx={styles.row}>
					<TextField
						fullWidth
						multiline
						rows={3}
						placeholder='Описание'
						value={description}
						onChange={e => setDescription(e.target.value)}
					/>
				</Box>

				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Промпты</Typography>
				</Box>
				<Box sx={styles.row}>
					<AddLine onClick={() => { addPromt(0) }} help='Добавить промпт' />
				</Box>
				{promts.map((promt, key) => <Promt key={key} idx={key} {...promt} add={addPromt} edit={editPromt} remove={deletePromt} />)}

				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Функции</Typography>
				</Box>
				<Box sx={styles.row}>
					<AddLine onClick={() => { addFunc(0) }} help='Добавить функцию' />
				</Box>
				{funcs.map((func, key) => (
					<Function
						key={key}
						idx={key}
						{...func}
						add={addFunc}
						edit={editFunc}
						remove={deleteFunc}
						addProperty={addProperty}
						editProperty={editProperty}
						deleteProperty={deleteProperty}
					/>
				))}
			</Box>
		</Box>
	)
}

const Promt = ({ idx, role, content, add, edit, remove }) => (
	<Box sx={styles.column}>
		<Box sx={styles.rowTitle}>
			<Typography variant='h4' fontWeight='bold'>Промпт {idx + 1}</Typography>
		</Box>
		<Box sx={styles.row}>
			<Box sx={styles.w25}>
				<Dropdown selected={role} items={roles} onChange={r => edit(idx, 'role', r)} />
			</Box>
			<Box sx={styles.wcustom1}>
				<TextField
					fullWidth
					multiline
					rows={3}
					placeholder='Промт. Например: Ты — чат-бот, который обрабатывает запросы, связанные с занятиями и записями на занятия...'
					value={content}
					onChange={e => edit(idx, 'content', e.target.value)}
				/>
			</Box>
			<Box sx={styles.wcustom2}>
				<Tooltip title={<TooltipTitle title='Удалить промпт' />} arrow>
					<IconButton aria-label='close' sx={styles.delete} onClick={() => remove(idx)}>
						<Close />
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
		<AddLine onClick={() => { add(idx + 1) }} help='Добавить промпт' />
	</Box>
)

const Function = ({ idx, name, description, properties, add, edit, remove, addProperty, editProperty, deleteProperty }) => (
	<Box sx={styles.column}>
		<Box sx={styles.rowTitle}>
			<Typography variant='h4' fontWeight='bold'>Функция {idx + 1}</Typography>
		</Box>
		<Box sx={styles.row}>
			<Box sx={styles.w50}>
				<TextField
					fullWidth
					placeholder='Название функции. Например: appointmentCheck'
					value={name}
					onChange={e => edit(idx, 'name', e.target.value)}
				/>
			</Box>
			<Box sx={styles.wcustom3}>
				<TextField
					fullWidth
					multiline
					rows={3}
					placeholder='Описание функции. Например: Когда нужно проверить доступность или расписание какого-либо занятия, следует вызвать эту функцию.'
					value={description}
					onChange={e => edit(idx, 'description', e.target.value)}
				/>
			</Box>
			<Box sx={styles.wcustom2}>
				<Tooltip title={<TooltipTitle title='Удалить функцию' />} arrow>
					<IconButton aria-label='close' sx={styles.delete} onClick={() => remove(idx)}>
						<Close />
					</IconButton>
				</Tooltip>
			</Box>
		</Box>

		<Box sx={styles.w75}>
			<AddLine onClick={() => { addProperty(idx, 0) }} help='Добавить переменную' />
		</Box>
		{properties.map((prop, key) => (
			<Property
				key={key}
				funcIdx={idx}
				idx={key}
				{...prop}
				add={addProperty}
				edit={editProperty}
				remove={deleteProperty}
			/>)
		)}

		<AddLine onClick={() => { add(idx + 1) }} help='Добавить функцию' />
	</Box>
)

const Property = ({ funcIdx, idx, name, description, required, type, format, example, add, edit, remove }) => (
	<Box sx={styles.column}>
		<Box sx={styles.rowTitle}>
			<Typography variant='h4' fontWeight='bold'>Переменная {idx + 1}</Typography>
			<Box sx={{ display: 'flex', ml: 2, alignItems: 'center' }}>
				<Checkbox color='primary' checked={!!required} onChange={e => edit(funcIdx, idx, 'required', e.target.checked)} />
				<Typography sx={{ ml: 1 }} variant='body1'>Обязательное поле</Typography>
			</Box>
		</Box>
		<Box sx={styles.row}>
			<Box sx={styles.w20}>
				<TextField
					fullWidth
					placeholder='Название переменной. Например: startdate'
					value={name}
					onChange={e => edit(funcIdx, idx, 'name', e.target.value)}
				/>
			</Box>
			<Box sx={styles.w15}>
				<Dropdown selected={type} items={propTypes} onChange={t => edit(funcIdx, idx, 'type', t)} />
			</Box>
			<Box sx={styles.w15}>
				<TextField
					fullWidth
					placeholder='Формат переменной. Например: date'
					value={format}
					onChange={e => edit(funcIdx, idx, 'format', e.target.value)}
				/>
			</Box>
			<Box sx={styles.w20}>
				<TextField
					fullWidth
					placeholder='Пример переменной. Например: 2023-07-23'
					value={example}
					onChange={e => edit(funcIdx, idx, 'example', e.target.value)}
				/>
			</Box>
			<Box sx={styles.wcustom4}>
				<TextField
					fullWidth
					multiline
					rows={3}
					placeholder='Описание переменной. Например: Дата, когда пользователь хочет записаться на занятие. Дата должна быть в формате ГГГГ-ММ-ДД.'
					value={description} onChange={e => edit(funcIdx, idx, 'description', e.target.value)}
				/>
			</Box>
			<Box sx={styles.wcustom2}>
				<Tooltip title={<TooltipTitle title='Удалить переменную' />} arrow>
					<IconButton aria-label='close' sx={styles.delete} onClick={() => remove(funcIdx, idx)}>
						<Close />
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
		<Box sx={styles.w75}>
			<AddLine onClick={() => { add(funcIdx, idx + 1) }} help='Добавить переменную' />
		</Box>
	</Box>
)

const styles = {
	main: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	buttons: {
		width: '100%',
		display: 'flex',
		justifyContent: 'end',
		mb: 3
	},
	container: {
		px: 2,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflowX: 'hidden',
		overflowY: 'scroll'
	},
	rowTitle: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		mt: 3,
		mb: 2
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	w15: {
		width: 'calc(15% - 16px)'
	},
	w20: {
		width: 'calc(20% - 16px)'
	},
	w25: {
		width: 'calc(25% - 16px)'
	},
	w50: {
		width: 'calc(50% - 16px)'
	},
	w75: {
		width: 'calc(75% - 16px)'
	},
	wcustom1: {
		width: 'calc(75% - 56px)'
	},
	wcustom2: {
		width: 40
	},
	wcustom3: {
		width: 'calc(50% - 56px)'
	},
	wcustom4: {
		width: 'calc(30% - 56px)'
	},
	column: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	delete: {
		color: theme => theme.palette.error.main
	}
}

export default StartNodeSettings