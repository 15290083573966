import React, { useCallback } from 'react'
import { Handle, Position } from '@xyflow/react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { FilterAlt } from '@mui/icons-material'

const FilterNode = ({ data, selected }) => {
	const theme = useTheme()

	const styles = useCallback(() => {
		return {
			main: {
				width: 150,
				backgroundColor: theme.palette.primary.filter,
				border: `1px solid ${theme.palette.primary.filter}`,
				borderRadius: 1,
				display: 'flex',
				flexDirection: 'column',
				boxShadow: selected ? `0px 0px 5px 3px ${theme.palette.primary.filter}7a` : 'none'
			},
			title: {
				p: 0.5,
				color: theme.palette.primary.contrastText,
				width: '100%',
				display: 'flex',
				justifyContent: 'center'
			},
			body: {
				minHeight: 60,
				width: '100%',
				py: 1,
				backgroundColor: theme.palette.primary.contrastText,
				borderRadius: 1,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			},
			port: {
				width: 10,
				height: 10,
				border: `2px solid ${theme.palette.primary.filter}`,
				backgroundColor: theme.palette.primary.contrastText
			},
			icon: {
				color: theme.palette.text.primary,
				fontSize: 42
			}
		}
	}, [selected, theme])

	return (
		<Box sx={styles().main}>
			<Handle type='target' position={Position.Top} id='target-1' style={styles().port} />
			<Handle type='target' position={Position.Left} id='target-2' style={styles().port} />

			<Box sx={styles().title}>
				<Typography variant='body3' fontWeight='bold' noWrap>Фильтрация массива</Typography>
			</Box>

			<Box sx={styles().body}>
				{
					data.description
						?
						<Typography variant='body3' textAlign='center' fontWeight='bold'>{data.description}</Typography>
						:
						<FilterAlt sx={styles().icon} />
				}
			</Box>

			<Handle type='source' position={Position.Right} id='source-1' style={styles().port} />
			<Handle type='source' position={Position.Bottom} id='source-2' style={styles().port} />
		</Box>
	)
}

export default FilterNode