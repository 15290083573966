import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'

const FilterNodeSettings = ({ toast, node, update }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [sourceName, setSourceName] = useState('')
	const [destinationPath, setDestinationPath] = useState('')
	const [condition, setCondition] = useState('')

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setSourceName(node?.data?.sourceName || '')
			setDestinationPath(node?.data?.destinationPath || '')
			setCondition(node?.data?.condition || '')
			setId(node?.id)
		}
	}, [id, node])

	const save = useCallback(() => {
		if (!sourceName) {
			toast('Пожалуйста введите название исходного массива', { type: 'error' })
			return
		}

		if (!destinationPath) {
			toast('Пожалуйста введите массив назначения', { type: 'error' })
			return
		}

		if (!condition) {
			toast('Пожалуйста введите условие для фильтрации', { type: 'error' })
			return
		}

		update({ ...node, data: { ...node.data, condition, description, sourceName, destinationPath } })
	}, [condition, description, destinationPath, node, sourceName, toast, update])

	return (
		<Box sx={styles.main}>
			<Box sx={styles.buttons}>
				<Button variant='contained' color='primary' onClick={save}>Сохранить</Button>
			</Box>
			<Box sx={styles.container}>
				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Определение компонента</Typography>
				</Box>
				<Box sx={styles.row}>
					<TextField
						fullWidth
						multiline
						rows={3}
						placeholder='Описание'
						value={description}
						onChange={e => setDescription(e.target.value)}
					/>
				</Box>

				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Информация о массиве</Typography>
				</Box>
				<Box sx={styles.row}>
					<Box sx={styles.w50}>
						<TextField
							fullWidth
							placeholder='Исходный массив'
							value={sourceName}
							onChange={e => setSourceName(e.target.value)}
						/>
					</Box>
					<Box sx={styles.w50}>
						<TextField
							fullWidth
							placeholder='Массив назначения'
							value={destinationPath}
							onChange={e => setDestinationPath(e.target.value)}
						/>
					</Box>
				</Box>

				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Условие для фильтрации</Typography>
				</Box>
				<Box sx={styles.row}>
					<TextField
						fullWidth
						multiline
						rows={3}
						placeholder={`this.item.type === 'personal'`}
						value={condition}
						onChange={e => setCondition(e.target.value)}
					/>
				</Box>
			</Box>
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	buttons: {
		width: '100%',
		display: 'flex',
		justifyContent: 'end',
		mb: 3
	},
	container: {
		px: 2,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflowX: 'hidden',
		overflowY: 'scroll'
	},
	rowTitle: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		mt: 3,
		mb: 2
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	w50: {
		width: 'calc(50% - 16px)'
	}
}

export default FilterNodeSettings