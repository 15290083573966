import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'

import Dropdown from '../custom/dropdown'
import { roles } from '../../helpers/constants'

const ChatAINodeSettings = ({ toast, node, update }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [role, setRole] = useState('system')
	const [content, setContent] = useState('')
	const [destinationPath, setDestinationPath] = useState('')

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setRole(node?.data?.role || 'system')
			setContent(node?.data?.content || '')
			setDestinationPath(node?.data?.destinationPath || '')
			setId(node?.id)
		}
	}, [id, node])

	const save = useCallback(() => {
		if (!content) {
			toast('Промпт не может быть пустым', { type: 'error' })
			return
		}

		if (!destinationPath) {
			toast('Пожалуйста введите значение для сохранения', { type: 'error' })
			return
		}

		update({ ...node, data: { ...node.data, description, role, content, destinationPath } })
	}, [content, description, destinationPath, node, role, toast, update])

	return (
		<Box sx={styles.main}>
			<Box sx={styles.buttons}>
				<Button variant='contained' color='primary' onClick={save}>Сохранить</Button>
			</Box>
			<Box sx={styles.container}>
				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Определение компонента</Typography>
				</Box>
				<Box sx={styles.row}>
					<TextField
						fullWidth
						multiline
						rows={3}
						placeholder='Описание'
						value={description}
						onChange={e => setDescription(e.target.value)}
					/>
				</Box>

				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Настройки</Typography>
				</Box>
				<Box sx={styles.row}>
					<Box sx={styles.w30}>
						<Dropdown selected={role} items={roles} onChange={item => setRole(item)} />
					</Box>
					<Box sx={styles.w70}>
						<TextField
							fullWidth
							placeholder='Значение для сохранения. Например: response'
							value={destinationPath}
							onChange={e => setDestinationPath(e.target.value)}
						/>
					</Box>
				</Box>
				<Box sx={styles.row}>
					<TextField
						fullWidth
						multiline
						rows={3}
						placeholder={`'У меня есть список преподавателей, выдаю через запятую: ' + this.instructors.map(t => t.name).join(', ') + '. Дайте мне из списка все самые подходящие по имени или фамилии ' + this.instructor + '. Если преподавателей несколько, дай мне список через запятую. Кроме преподавателей ничего не пиши.'`}
						value={content}
						onChange={e => setContent(e.target.value)}
					/>
				</Box>
			</Box>
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	buttons: {
		width: '100%',
		display: 'flex',
		justifyContent: 'end',
		mb: 3
	},
	container: {
		px: 2,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflowX: 'hidden',
		overflowY: 'scroll'
	},
	rowTitle: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		mt: 3,
		mb: 2
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		mb: 2
	},
	w30: {
		width: 'calc(30% - 16px)'
	},
	w70: {
		width: 'calc(70% - 16px)'
	}
}

export default ChatAINodeSettings