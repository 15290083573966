import React from 'react'
import { Box, Typography } from '@mui/material'

const ApiNode = ({ data, selected }) => {
	return (
		<Box sx={selected ? styles.mainSelected : styles.main}>
			<Box sx={styles.title}>
				<Typography variant='body3' fontWeight='bold' noWrap>API запрос</Typography>
			</Box>

			<Box sx={styles.body}>
				{
					data.name
						?
						<Typography variant='body3' textAlign='center' fontWeight='bold'>{data.name}</Typography>
						:
						<Typography variant='h3' fontWeight='bold' noWrap>API</Typography>
				}
			</Box>
		</Box>
	)
}

const styles = {
	main: {
		width: 150,
		backgroundColor: theme => theme.palette.primary.api,
		border: theme => `1px solid ${theme.palette.primary.api}`,
		borderRadius: 1,
		display: 'flex',
		flexDirection: 'column'
	},
	mainSelected: {
		width: 150,
		backgroundColor: theme => theme.palette.primary.api,
		border: theme => `1px solid ${theme.palette.primary.api}`,
		borderRadius: 1,
		display: 'flex',
		flexDirection: 'column',
		boxShadow: theme => `0px 0px 5px 3px ${theme.palette.primary.api}7a`
	},
	title: {
		p: 0.5,
		color: theme => theme.palette.primary.contrastText,
		width: '100%',
		display: 'flex',
		justifyContent: 'center'
	},
	body: {
		width: '100%',
		py: 2,
		color: theme => theme.palette.text.primary,
		backgroundColor: theme => theme.palette.primary.contrastText,
		borderRadius: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}

export default ApiNode