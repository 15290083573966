import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material'

import AddLine from '../custom/add-line'
import TooltipTitle from '../custom/tooltip-title'
import { Close } from '@mui/icons-material'

const MapperNodeSettings = ({ toast, node, update }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [sourceName, setSourceName] = useState('')
	const [destinationPath, setDestinationPath] = useState('')
	const [values, setValues] = useState([])

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setSourceName(node?.data?.sourceName || '')
			setDestinationPath(node?.data?.destinationPath || '')
			setValues(node?.data?.values || [])
			setId(node?.id)
		}
	}, [id, node])

	const add = idx => {
		setValues(vals => {
			vals.splice(idx, 0, { sourceName: '', destinationPath: '' })
			return [...vals]
		})
	}

	const edit = (idx, key, value) => {
		setValues(vals => [...vals.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
	}

	const remove = idx => {
		setValues(vals => [...vals.filter((_, i) => i !== idx)])
	}

	const save = useCallback(() => {
		if (!sourceName) {
			toast('Пожалуйста введите название исходного массива', { type: 'error' })
			return
		}

		if (!destinationPath) {
			toast('Пожалуйста введите массив назначения', { type: 'error' })
			return
		}

		for (const value of values) {
			if (!value.sourceName) {
				toast('Пожалуйста введите название исходного элемента', { type: 'error' })
				return
			}

			if (!value.destinationPath) {
				toast('Пожалуйста введите путь назначения', { type: 'error' })
				return
			}
		}

		update({ ...node, data: { ...node.data, description, sourceName, destinationPath, values } })
	}, [description, destinationPath, node, sourceName, toast, update, values])

	return (
		<Box sx={styles.main}>
			<Box sx={styles.buttons}>
				<Button variant='contained' color='primary' onClick={save}>Сохранить</Button>
			</Box>
			<Box sx={styles.container}>
				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Определение компонента</Typography>
				</Box>
				<Box sx={styles.row}>
					<TextField
						fullWidth
						multiline
						rows={3}
						placeholder='Описание'
						value={description}
						onChange={e => setDescription(e.target.value)}
					/>
				</Box>

				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Информация о массиве</Typography>
				</Box>
				<Box sx={styles.row}>
					<Box sx={styles.w50}>
						<TextField
							fullWidth
							placeholder='Исходный массив'
							value={sourceName}
							onChange={e => setSourceName(e.target.value)}
						/>
					</Box>
					<Box sx={styles.w50}>
						<TextField
							fullWidth
							placeholder='Массив назначения'
							value={destinationPath}
							onChange={e => setDestinationPath(e.target.value)}
						/>
					</Box>
				</Box>

				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Значения</Typography>
				</Box>
				<Box sx={styles.row}>
					<AddLine onClick={() => { add(0) }} help='Добавить значение' />
				</Box>
				{values.map((value, key) => <Value key={key} idx={key} {...value} add={add} edit={edit} remove={remove} />)}
			</Box>
		</Box>
	)
}

const Value = ({ idx, sourceName, destinationPath, add, edit, remove }) => (
	<Box sx={styles.column}>
		<Box sx={styles.row}>
			<Box sx={styles.source}>
				<TextField
					fullWidth
					placeholder='Значение из элемента исходного массива. Пример: service.title'
					value={sourceName}
					onChange={e => edit(idx, 'sourceName', e.target.value)}
				/>
			</Box>
			<Box sx={styles.source}>
				<TextField
					fullWidth
					placeholder='Значение для сохранение в элемент массива назначения. Пример: name'
					value={destinationPath}
					onChange={e => edit(idx, 'destinationPath', e.target.value)}
				/>
			</Box>
			<Box sx={styles.icon}>
				<Tooltip title={<TooltipTitle title='Удалить' />} arrow>
					<IconButton aria-label='close' sx={styles.delete} onClick={() => remove(idx)}>
						<Close />
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
		<Box sx={styles.row}>
			<AddLine onClick={() => { add(idx + 1) }} help='Добавить значение' />
		</Box>
	</Box>
)

const styles = {
	main: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	buttons: {
		width: '100%',
		display: 'flex',
		justifyContent: 'end',
		mb: 3
	},
	container: {
		px: 2,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflowX: 'hidden',
		overflowY: 'scroll'
	},
	rowTitle: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		mt: 3,
		mb: 2
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	source: {
		width: 'calc(50% - 36px)'
	},
	icon: {
		width: 40
	},
	column: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	delete: {
		color: theme => theme.palette.error.main
	},
	w50: {
		width: 'calc(50% - 16px)'
	}
}

export default MapperNodeSettings