import { v4 as uuidv4 } from 'uuid'

export const checkNodes = (source, target) => {
	if (source === 'start' && target !== 'function')
		return 'После компонента "Старт" должен следовать только компонент "Функция"'

	if (source !== 'start' && target === 'function')
		return 'К компоненту "Функция" можно подключить только компонент "Старт"'

	return null
}

export const checkEdges = (source, target, edges, newEdge) => {
	if (edges.find(edge => edge.source === source?.id && edge.target === target?.id))
		return 'Компоненты можно соединить лишь одной связью'

	if (edges.find(edge => edge.source === newEdge.source && edge.sourceHandle === newEdge.sourceHandle))
		return 'Из одной точки может выходить только одна связь'

	if (!['start', 'checkConditions'].includes(source?.type) && edges.find(edge => edge.source === newEdge.source))
		return 'Из одного компонента может выходить только одна связь'

	return null
}

export const getCommandParams = (node, edges) => {
	switch (node.type) {
		case 'runScenario':
		case 'mapper':
		case 'filter':
			return { [node.type]: node.data }
		case 'save_variables':
			return { save_variables: node.data.values || [] }
		case 'getResult':
		case 'chatAI':
			return { runFunction: { name: node.type, parameters: node.data } }
		case 'checkParams': {
			const params = {}
			node.data?.params?.forEach(param => { params[param.name] = param.value })
			return { checkParams: params }
		}
		case 'checkConditions':
			return {
				checkConditions: node.data?.conditions
					?.filter(cond => cond.name !== 'По умолчанию')
					?.map(cond => ({
						next: edges.find(edge => edge.sourceHandle === cond.id)?.target || undefined,
						value: cond.value
					}))
			}
		default:
			return {}
	}
}

export const initialData = type => {
	switch (type) {
		case 'api':
			return { name: '', webhooks: [], conditions: [], actions: [] }
		case 'chatAI':
			return { description: '', role: 'system', content: '', destinationPath: '' }
		case 'checkConditions':
			return { description: '', conditions: [{ id: uuidv4(), name: 'По умолчанию', value: '' }] }
		case 'checkParams':
			return { description: '', params: [] }
		case 'filter':
			return { description: '', sourceName: '', destinationPath: '', condition: '' }
		case 'mapper':
			return { description: '', sourceName: '', destinationPath: '', values: [] }
		case 'getResult':
			return { description: '', result: '' }
		case 'runScenario':
			return { name: '', description: '' }
		case 'save_variables':
			return { description: '', values: [] }
		case 'start':
			return { description: '', promts: [], funcs: [] }
		case 'function':
		default: return {}
	}
}