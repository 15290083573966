import React from 'react'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import { Help } from '@mui/icons-material'

import { useDnD } from './context'
import TooltipTitle from '../custom/tooltip-title'
import { components } from '../../helpers/constants'

const Navigation = ({ save, saving }) => {
	const [, setType] = useDnD()

	const onDragStart = (evt, nodeType) => {
		setType(nodeType)
		evt.dataTransfer.effectAllowed = 'move'
	}

	return <Box sx={styles.main}>
		<Box sx={styles.container}>
			{
				components.map((item, key) => (
					<React.Fragment key={key}>
						{item.title && <Box sx={styles.title}>
							<Typography variant='h4' fontWeight='bold' noWrap>{item.title}</Typography>
						</Box>}
						{
							item.items.map((it, key) => (
								<Box sx={styles.item} key={key} onDragStart={(event) => onDragStart(event, it.type)} draggable >
									<Typography variant='body1' noWrap>{it.title}</Typography>
									<Tooltip title={<TooltipTitle title={it.help} />} arrow>
										<Help sx={styles.icon} />
									</Tooltip>
								</Box>
							))
						}
					</React.Fragment>
				))
			}
		</Box>

		<Button variant='contained' sx={styles.button} disabled={saving} onClick={save}>{saving ? 'Сохраняется ...' : 'Сохранить'}</Button>
	</Box>
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	title: {
		width: '100%',
		pl: 2,
		pr: 1,
		py: 2
	},
	item: {
		width: '100%',
		display: 'flex',
		pl: 4,
		pr: 1,
		py: 0.75,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		cursor: 'pointer'
	},
	icon: {
		color: theme => theme.palette.primary.border,
		fontSize: 18,
		ml: 1
	},
	button: {
		mx: 2,
		my: 4
	}
}

export default Navigation