import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import Dropdown from '../custom/dropdown'
import { contentTypes, methods, propTypes, requiredEntryTypes } from '../../helpers/constants'
import AddLine from '../custom/add-line'
import TooltipTitle from '../custom/tooltip-title'

const WebhookDialog = ({ webhook, toast, onClose, save }) => {
	const [name, setName] = useState('')
	const [url, setUrl] = useState('')
	const [method, setMethod] = useState('GET')
	const [contentType, setContentType] = useState('application/json')
	const [headers, setHeaders] = useState([{ 'key': 'Content-Type', 'value': 'application/json' }])
	const [cookies, setCookies] = useState([])
	const [body, setBody] = useState([])
	const [queryParams, setQueryParams] = useState([])
	const [requiredEntry, setRequiredEntry] = useState([])
	const [responseSchema, setResponseSchema] = useState(null)
	const [result, setResult] = useState([])

	useEffect(() => {
		setName(webhook?.name || '')
		setUrl(webhook?.url || '')
		setMethod(webhook?.method || 'GET')
		setContentType(webhook?.contentType || 'application/json')
		setHeaders(webhook?.headers || [{ 'key': 'Content-Type', 'value': 'application/json' }])
		setCookies(webhook?.cookies || [])
		setBody(webhook?.body || [])
		setQueryParams(webhook?.queryParams || [])
		setRequiredEntry(webhook?.requiredEntry || [])
		setResponseSchema(webhook?.responseSchema || null)
		setResult(webhook?.result || [])
	}, [webhook])

	const add = (type, idx) => {
		if (type === 'cookie') {
			setCookies(cks => {
				cks.splice(idx, 0, { key: '', value: '' })
				return [...cks]
			})
		} else if (type === 'header') {
			setHeaders(hdrs => {
				hdrs.splice(idx, 0, { key: '', value: '' })
				return [...hdrs]
			})
		} else if (type === 'body') {
			setBody(bd => {
				bd.splice(idx, 0, { key: '', value: '' })
				return [...bd]
			})
		} else if (type === 'param') {
			setQueryParams(qps => {
				qps.splice(idx, 0, { key: '', value: '' })
				return [...qps]
			})
		} else if (type === 'entry') {
			setRequiredEntry(res => {
				res.splice(idx, 0, { type: 'queryParams', nullable: false, sourceName: '', destinationPath: '', destinationType: 'string' })
				return [...res]
			})
		} else if (type === 'result') {
			setResult(res => {
				res.splice(idx, 0, { from: '', type: 'string', variable: '' })
				return [...res]
			})
		}
	}

	const edit = (type, idx, key, value) => {
		if (type === 'cookie') {
			setCookies(cks => [...cks.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
		} else if (type === 'header') {
			setHeaders(hdrs => [...hdrs.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
		} else if (type === 'body') {
			setBody(bd => [...bd.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
		} else if (type === 'param') {
			setQueryParams(qps => [...qps.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
		} else if (type === 'entry') {
			setRequiredEntry(res => [...res.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
		} else if (type === 'result') {
			setResult(res => [...res.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
		}
	}

	const remove = (type, idx) => {
		if (type === 'cookie') {
			setCookies(cks => [...cks.filter((_, i) => i !== idx)])
		} else if (type === 'header') {
			setHeaders(hdrs => [...hdrs.filter((_, i) => i !== idx)])
		} else if (type === 'body') {
			setBody(bd => [...bd.filter((_, i) => i !== idx)])
		} else if (type === 'param') {
			setQueryParams(qps => [...qps.filter((_, i) => i !== idx)])
		} else if (type === 'entry') {
			setRequiredEntry(res => [...res.filter((_, i) => i !== idx)])
		} else if (type === 'result') {
			setResult(res => [...res.filter((_, i) => i !== idx)])
		}
	}

	const onSave = useCallback(() => {
		if (!name) {
			toast('Пожалуйста введите название', { type: 'error' })
			return
		}

		if (!url) {
			toast('Пожалуйста введите URL', { type: 'error' })
			return
		}

		if (responseSchema) {
			try {
				JSON.parse(responseSchema)
			} catch (err) {
				console.log('err: ', err)
				toast(`Схема ответа (Response Schema): Объект JSON недействителен. Ошибка: ${err?.message}`, { type: 'error' })
				return
			}
		}

		save({
			name,
			url,
			method,
			contentType,
			headers,
			cookies,
			body,
			queryParams,
			requiredEntry,
			responseSchema,
			result
		})
	}, [body, contentType, cookies, headers, method, name, queryParams, requiredEntry, responseSchema, result, save, toast, url])

	return <Dialog open maxWidth='lg' fullWidth={true}>
		<DialogTitle sx={styles.title}>
			<Typography variant='h4' fontWeight='bold'>{webhook ? 'Изменение настроек' : 'Добавление'} вебхука</Typography>
			<IconButton aria-label='close' onClick={onClose} sx={styles.closeIcon}>
				<Close />
			</IconButton>
		</DialogTitle>
		<DialogContent dividers>
			<Box sx={styles.body}>
				<Box sx={styles.row}>
					<Box sx={styles.w50}>
						<TextField fullWidth placeholder='Название' value={name} onChange={e => setName(e.target.value)} />
					</Box>
					<Box sx={styles.w50}>
						<TextField fullWidth placeholder='URL' value={url} onChange={e => setUrl(e.target.value)} />
					</Box>
				</Box>
				<Box sx={styles.row}>
					<Box sx={styles.w50}>
						<Dropdown selected={method} items={methods} onChange={item => setMethod(item)} />
					</Box>
					<Box sx={styles.w50}>
						<Dropdown selected={contentType} items={contentTypes} onChange={item => setContentType(item)} />
					</Box>
				</Box>

				{
					[
						{ title: 'Заголовки (Headers)', type: 'header', items: headers },
						{ title: 'Куки (Cookies)', type: 'cookie', items: cookies },
						{ title: 'Тело (Body)', type: 'body', items: body },
						{ title: 'Параметры (Query Parameters)', type: 'param', items: queryParams },
						{ title: 'Обязательные записи (Required Entries)', type: 'entry', items: requiredEntry }
					].map((item, index) => (
						<Items
							key={index}
							title={item.title}
							type={item.type}
							items={item.items}
							add={idx => { add(item.type, idx) }}
							edit={(idx, key, value) => { edit(item.type, idx, key, value) }}
							remove={idx => { remove(item.type, idx) }}
						/>
					))
				}

				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Схема ответа (Response Schema)</Typography>
				</Box>
				<Box sx={styles.row}>
					<TextField
						fullWidth
						multiline
						rows={5}
						placeholder='{ "type": "object", "required": ["result"], "properties": { "result": { "type": "boolean" } } }'
						value={responseSchema}
						onChange={e => setResponseSchema(e.target.value)}
					/>
				</Box>

				<Items
					title='Сохранение ответа в переменную'
					type='result'
					items={result}
					add={idx => { add('result', idx) }}
					edit={(idx, key, value) => { edit('result', idx, key, value) }}
					remove={idx => { remove('result', idx) }}
				/>
			</Box>
		</DialogContent>
		<DialogActions>
			<Button variant='contained' onClick={onSave}>{webhook ? 'Сохранить' : 'Добавить'}</Button>
		</DialogActions>
	</Dialog>
}

const Items = ({ title, type, items, add, edit, remove }) => (
	<Box sx={styles.column}>
		<Box sx={styles.rowTitle}>
			<Typography variant='h4' fontWeight='bold'>{title}</Typography>
		</Box>
		<AddLine onClick={() => { add(0) }} help='Добавить' />
		<Box sx={styles.column}>
			{
				items.map((item, key) => (
					<Item
						key={key}
						type={type}
						item={item}
						add={() => { add(key + 1) }}
						edit={(k, v) => { edit(key, k, v) }}
						remove={() => { remove(key) }}
					/>
				))
			}
		</Box>
	</Box>
)

const Item = ({ type, item, add, edit, remove }) => (
	<Box sx={styles.column}>
		{
			['cookie', 'header', 'body', 'param'].includes(type)
				?
				<KeyValueItem item={item} edit={edit} remove={remove} />
				:
				type === 'entry'
					?
					<RequiredEntryItem item={item} edit={edit} remove={remove} />
					:
					type === 'result'
						?
						<ResultItem item={item} edit={edit} remove={remove} />
						:
						<></>
		}
		<Box sx={styles.row}>
			<AddLine onClick={add} help='Добавить' />
		</Box>
	</Box>
)

const KeyValueItem = ({ item, edit, remove }) => (
	<Box sx={styles.row}>
		<Box sx={styles.custom50}>
			<TextField fullWidth placeholder='Ключ' value={item.key} onChange={e => edit('key', e.target.value)} />
		</Box>
		<Box sx={styles.custom50}>
			<TextField fullWidth placeholder='Значение' value={item.value} onChange={e => edit('value', e.target.value)} />
		</Box>
		<Box sx={styles.icon}>
			<Tooltip title={<TooltipTitle title='Удалить' />} arrow>
				<IconButton aria-label='close' sx={styles.delete} onClick={remove}>
					<Close />
				</IconButton>
			</Tooltip>
		</Box>
	</Box>
)

const RequiredEntryItem = ({ item, edit, remove }) => (
	<Box sx={styles.row}>
		<Box sx={styles.custom20}>
			<Dropdown selected={item.type} items={requiredEntryTypes} onChange={item => edit('type', item)} />
		</Box>
		<Box sx={styles.custom20}>
			<Checkbox checked={!!item.nullable} onChange={e => edit('nullable', e.target.checked)} />
			<Typography sx={{ ml: 1 }} variant='body1'>Nullable</Typography>
		</Box>
		<Box sx={styles.custom20}>
			<TextField fullWidth placeholder='Исходное значение' value={item.sourceName} onChange={e => edit('sourceName', e.target.value)} />
		</Box>
		<Box sx={styles.custom20}>
			<TextField fullWidth placeholder='Значение для сохранения' value={item.destinationPath} onChange={e => edit('destinationPath', e.target.value)} />
		</Box>
		<Box sx={styles.custom20}>
			<Dropdown selected={item.destinationType} items={propTypes} onChange={item => edit('destinationType', item)} />
		</Box>
		<Box sx={styles.icon}>
			<Tooltip title={<TooltipTitle title='Удалить' />} arrow>
				<IconButton aria-label='close' sx={styles.delete} onClick={remove}>
					<Close />
				</IconButton>
			</Tooltip>
		</Box>
	</Box>
)

const ResultItem = ({ item, edit, remove }) => (
	<Box sx={styles.row}>
		<Box sx={styles.custom33}>
			<TextField fullWidth placeholder='Исходное значение: response.user_token' value={item.from} onChange={e => edit('from', e.target.value)} />
		</Box>
		<Box sx={styles.custom33}>
			<Dropdown selected={item.type} items={propTypes} onChange={item => edit('type', item)} />
		</Box>
		<Box sx={styles.custom33}>
			<TextField fullWidth placeholder='Значение для сохранения: usertoken' value={item.variable} onChange={e => edit('variable', e.target.value)} />
		</Box>
		<Box sx={styles.icon}>
			<Tooltip title={<TooltipTitle title='Удалить' />} arrow>
				<IconButton aria-label='close' sx={styles.delete} onClick={remove}>
					<Close />
				</IconButton>
			</Tooltip>
		</Box>
	</Box>
)

const styles = {
	title: {
		m: 0,
		p: 2,
		fontWeight: 900
	},
	closeIcon: {
		position: 'absolute',
		right: 8,
		top: 8,
		color: theme => theme.palette.text.secondary
	},
	body: {
		my: 1,
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	bodyText: {
		display: 'block'
	},
	description: {
		width: '100%',
		pt: 4
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		mb: 2
	},
	custom50: {
		width: 'calc(50% - 36px)'
	},
	custom20: {
		width: 'calc(20% - 24px)',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	custom33: {
		width: 'calc(33% - 29px)',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	w50: {
		width: 'calc(50% - 16px)'
	},
	rowTitle: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		mt: 3,
		mb: 2
	},
	icon: {
		width: 40,
		display: 'flex',
		alignItems: 'center',
	},
	delete: {
		color: theme => theme.palette.error.main
	},
}

export default WebhookDialog