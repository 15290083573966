import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { v4 as uuidv4 } from 'uuid'

import AddLine from '../custom/add-line'
import TooltipTitle from '../custom/tooltip-title'

const CheckConditionsNodeSettings = ({ toast, node, update }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [conditions, setConditions] = useState([])

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setConditions(node?.data?.conditions || [{ id: uuidv4(), name: 'По умолчанию', value: '' }])
			setId(node?.id)
		}
	}, [id, node])

	const add = idx => {
		setConditions(cnds => {
			cnds.splice(idx, 0, { id: uuidv4(), name: '', value: '' })
			return [...cnds]
		})
	}

	const edit = (idx, key, value) => {
		setConditions(cnds => [...cnds.map((c, i) => ({ ...c, [key]: i === idx ? value : c[key] }))])
	}

	const remove = idx => {
		setConditions(cnds => [...cnds.filter((_, i) => i !== idx)])
	}

	const save = useCallback(() => {
		for (const condition of conditions) {
			if (!condition.name) {
				toast('Пожалуйста введите текст для условия', { type: 'error' })
				return
			}

			if (!condition.name === 'По умолчанию' && !condition.value) {
				toast('Пожалуйста введите условие', { type: 'error' })
				return
			}
		}

		update({ ...node, data: { ...node.data, description, conditions } })
	}, [conditions, description, node, toast, update])


	return (
		<Box sx={styles.main}>
			<Box sx={styles.buttons}>
				<Button variant='contained' color='primary' onClick={save}>Сохранить</Button>
			</Box>
			<Box sx={styles.container}>
				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Определение компонента</Typography>
				</Box>
				<Box sx={styles.row}>
					<TextField
						fullWidth
						multiline
						rows={3}
						placeholder='Описание'
						value={description}
						onChange={e => setDescription(e.target.value)}
					/>
				</Box>

				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Условия</Typography>
				</Box>
				<Box sx={styles.row}>
					<AddLine onClick={() => { add(0) }} help='Добавить условие' />
				</Box>
				{conditions.map((condition, key) => <Condition key={key} idx={key} {...condition} add={add} edit={edit} remove={remove} />)}
			</Box>
		</Box>
	)
}

const Condition = ({ idx, name, value, add, edit, remove }) => (
	<Box sx={styles.column}>
		<Box sx={styles.row}>
			<Box sx={styles.name}>
				<TextField
					fullWidth
					disabled={name === 'По умолчанию'}
					placeholder='Проверка инструктора'
					value={name}
					onChange={e => edit(idx, 'name', e.target.value)}
				/>
			</Box>
			<Box sx={styles.condition}>
				<TextField
					fullWidth
					multiline
					rows={3}
					disabled={name === 'По умолчанию'}
					placeholder='!!variables.instructor && !variables.lesson'
					value={value}
					onChange={e => edit(idx, 'value', e.target.value)}
				/>
			</Box>
			<Box sx={styles.icon}>
				{name !== 'По умолчанию' && <Tooltip title={<TooltipTitle title='Удалить' />} arrow>
					<IconButton aria-label='close' sx={styles.delete} onClick={() => remove(idx)}>
						<Close />
					</IconButton>
				</Tooltip>}
			</Box>
		</Box>

		{name !== 'По умолчанию' && <AddLine onClick={() => { add(idx + 1) }} help='Добавить условие' />}
	</Box>
)

const styles = {
	main: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	buttons: {
		width: '100%',
		display: 'flex',
		justifyContent: 'end',
		mb: 3
	},
	container: {
		px: 2,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflowX: 'hidden',
		overflowY: 'scroll'
	},
	rowTitle: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		mt: 3,
		mb: 2
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	name: {
		width: 'calc(30% - 16px)'
	},
	condition: {
		width: 'calc(60% - 56px)'
	},
	icon: {
		width: 40
	},
	column: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	delete: {
		color: theme => theme.palette.error.main
	}
}

export default CheckConditionsNodeSettings