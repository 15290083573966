import React from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import { Add } from '@mui/icons-material'
import TooltipTitle from './tooltip-title'

const AddLine = ({ onClick, help }) => (
	<Box sx={styles.container}>
		<Box sx={styles.line} />
		<Tooltip title={<TooltipTitle title={help} />} arrow>
			<IconButton aria-label='close' onClick={onClick} sx={styles.icon}>
				<Add />
			</IconButton>
		</Tooltip>
	</Box>
)

const styles = {
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	line: {
		width: 'calc(100% - 70px)',
		borderTop: theme => `1px solid ${theme.palette.primary.border}`
	},
	icon: {
		color: theme => theme.palette.text.secondary
	}
}

export default AddLine