import { createTheme } from '@mui/material/styles'

const theme = createTheme({
	breakpoints: { values: { xs: 0, sm: 860, md: 1080, lg: 1440, xl: 2000 } },
	shape: { borderRadius: 4 },
	typography: {
		button: { fontWeight: 400 },
		fontFamily: 'Nunito',
		body1: {
			fontSize: 16,
			lineHeight: '22.5px'
		},
		body2: {
			fontSize: 14,
			lineHeight: '20.5px'
		},
		body3: {
			fontSize: 12,
			lineHeight: '15.6px'
		},
		title: {
			fontSize: 21,
			lineHeight: '25px'
		},
		h1: {
			fontSize: 32.5,
			lineHeight: '37.263px'
		},
		h2: {
			fontSize: 26,
			lineHeight: '30.3662px'
		},
		h3: {
			fontSize: 22.8,
			lineHeight: '26.9179px'
		},
		h4: {
			fontSize: 19.5,
			lineHeight: '23.4px'
		},
		h5: {
			fontSize: 16.25,
			lineHeight: '19.5px'
		},
		h6: {
			fontSize: 14,
			lineHeight: '15.6px'
		}
	},
	palette: {
		mode: 'light',
		primary: {
			main: '#0014ff',
			dark: '#364574',
			light: '#F3F6F9',
			contrastText: '#FFFFFF',
			border: '#BCBCBC',
			api: '#00a4e4',
			chatAI: '#00a78e',
			checkConditions: '#7552cc',
			checkParams: '#008eaa',
			filter: '#ce181e',
			mapper: '#ff9900',
			result: '#7f181b',
			runApi: '#004d73',
			saveVariable: '#ff6319',
			scenario: '#d52685'
		},
		secondary: {
			main: '#3577F1',
			dark: '#878A99',
			light: '#FFFFFF',
			contrastText: '#FFFFFF'
		},
		background: {
			default: '#FFFFFF',
			paper: '#F3F6F9'
		},
		error: {
			main: '#F06548'
		},
		success: {
			main: '#0AB39C'
		},
		info: {
			main: '#299CDB'
		},
		warning: {
			main: '#F7B84B'
		},
		text: {
			primary: '#212529',
			secondary: '#2D65CD',
			success: '#099885',
			info: '#2385BA',
			warning: '#D29C40',
			danger: '#CC563D',
			light: '#CED4DA',
			dark: '#343A40'
		}
	},
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true
			},
			styleOverrides: {
				root: {
					textTransform: 'none'
				},
				sizeSmall: {
					padding: '4px 8px',
					fontSize: 11.375,
					lineHeight: '17.0625px',
					whiteSpace: 'nowrap'
				},
				sizeMedium: {
					padding: '8.5px 14.4px',
					fontSize: 14,
					lineHeight: '20.5px',
					whiteSpace: 'nowrap'
				},
				sizeLarge: {
					padding: '11.2px 19.2px',
					fontSize: 16.25,
					lineHeight: '24.375px',
					whiteSpace: 'nowrap'
				},
				textSizeSmall: {
					padding: '4px 8px'
				},
				textSizeMedium: {
					padding: '8.5px 14.4px'
				},
				textSizeLarge: {
					padding: '11.2px 19.2px'
				},
				containedPrimary: {
					backgroundColor: '#0014ff',
					color: '#FFFFFF',
					'&:hover': {
						backgroundColor: '#0014ffbf'
					},
					'&:active': {
						backgroundColor: '#0014ff'
					}
				},
				containedSecondary: {
					backgroundColor: '#3577F1',
					color: '#FFFFFF',
					'&:hover': {
						backgroundColor: '#2D65CD'
					},
					'&:active': {
						backgroundColor: '#2D65CD'
					}
				},
				containedInherit: {
					backgroundColor: '#F3F6F9',
					color: '#212529',
					'&:hover': {
						backgroundColor: '#CED4DA'
					},
					'&:active': {
						backgroundColor: '#CED4DA'
					}
				}
			}
		},
		MuiCssBaseline: {
			styleOverrides: {
				'*': {
					boxSizing: 'border-box',
					margin: 0,
					padding: 0
				},
				html: {
					MozOsxFontSmoothing: 'grayscale',
					WebkitFontSmoothing: 'antialiased',
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100%',
					width: '100%',
					minWidth: '600px'
				},
				'#root': {
					display: 'flex',
					flex: '1 1 auto',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
					'&::backdrop': {
						background: 'rgba(0,0,0,0)'
					}
				},
				body: {
					display: 'flex',
					flex: '1 1 auto',
					flexDirection: 'column',
					minHeight: '100%',
					width: '100%',
					backgroundColor: `#F3F6F9`,
					userSelect: 'none',
					'&::-webkit-scrollbar, & *::-webkit-scrollbar': {
						width: 5,
						height: 5
					},
					'&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
						borderRadius: 5,
						backgroundColor: `#CED4DA`,
						minHeight: 24
					},
					'&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
						backgroundColor: `#CED4DA`
					}
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'&.MuiInputBase-root': {
						marginTop: 8,
						'& fieldset': {
							borderColor: '#CED4DA',
							borderRadius: 4
						}
					}
				},
				sizeSmall: {
					fontSize: 14,
					lineHeight: '20.5px',
					fontWeight: 400
				},
				input: {
					'&.MuiInputBase-inputSizeSmall': {
						height: 20.5,
						padding: '8.5px',
					}
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: '#FFFFFF'
				}
			}
		}
	}
})

export default theme