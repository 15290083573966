
export const components = [
	{
		title: 'Сценарии',
		items: [
			{
				title: 'Старт',
				type: 'start',
				help: 'Начальный шаг, который активирует процесс обработки данных. Здесь пользователь выбирает, какую операцию выполнить.'
			},
			{
				title: 'Функция',
				type: 'function',
				help: 'Функция срабатывает, когда в стартовом сценарии пользователь выбирает необходимую функцию для выполнения. Цель функции — выполнение определённого действия, которое было выбрано пользователем в стартовом сценарии. Например, проверить доступность занятий, записать пользователя на занятие или отменить запись.'
			},
			{
				title: 'API запрос',
				type: 'api',
				help: 'API-сценарии взаимодействуют между системойинтеграции IntellecDialog и внешними сервисами. Сценарии содержат вебхуки для отправки и получения данных, а также действия, которые определяют последовательность выполнения задач.'
			}
		]
	},
	{
		title: 'Команды',
		items: [
			{
				title: 'Проверка',
				type: 'checkParams',
				help: 'Проверяет, ввёл ли пользователь обязательные параметры (например, дату и время занятия)'
			},
			{
				title: 'Запуск API',
				type: 'runScenario',
				help: 'Запускает API-запрос'
			},
			{
				title: 'Условия',
				type: 'checkConditions',
				help: 'Проверяет условия для перехода на следующий шаг. Например, если введены все параметры, продолжить процесс'
			},
			{
				title: 'Mapper',
				type: 'mapper',
				help: 'Преобразует или форматирует данные (массив), чтобы их можно было использовать на следующем шаге'
			},
			{
				title: 'Фильтрация',
				type: 'filter',
				help: 'Отбирает только те данные, которые соответствуют заданным условиям. Например, фильтрация только персональных занятий.'
			},
			{
				title: 'Сохранение',
				type: 'save_variables',
				help: 'Сохраняет данные, полученные на предыдущих шагах, для дальнейшего использования'
			},
			{
				title: 'Результат',
				type: 'getResult',
				help: 'Отправляет пользователю текстовый ответ'
			},
			{
				title: 'ИИ запрос',
				type: 'chatAI',
				help: 'Отправляет запрос в ChatGPT и сохраняет полученный ответ.'
			}
		]
	}
]

export const roles = [
	{ id: 'system', name: 'System (системный промпт)' },
	{ id: 'user', name: 'User (промпт пользователя)' },
	{ id: 'assistant', name: 'Assistant (ответ ассистента)' }
]

export const propTypes = [
	{ id: 'string', name: 'String: Текстовое значение' },
	{ id: 'number', name: 'Number: Числовое значение' },
	{ id: 'array', name: 'Array: Массив' },
	{ id: 'object', name: 'Object: JSON Объект' }
]

export const methods = [
	{ id: 'GET', name: 'GET' },
	{ id: 'POST', name: 'POST' },
	{ id: 'PUT', name: 'PUT' },
	{ id: 'DELETE', name: 'DELETE' }
]

export const contentTypes = [
	{ id: 'application/json', name: 'application/json' },
	{ id: 'application/x-www-form-urlencoded', name: 'application/x-www-form-urlencoded' }
]

export const requiredEntryTypes = [
	{ id: 'headers', name: 'Заголовки (Headers)' },
	{ id: 'cookies', name: 'Куки (Cookies)' },
	{ id: 'body', name: 'Тело (Body)' },
	{ id: 'queryParams', name: 'Параметры (Query Parameters)' }
]

export const conditionTypes = [
	{ id: 'update_auth_header', name: 'update_auth_header' },
	{ id: 'update_auth_query', name: 'update_auth_query' }
]