import axios from 'axios'
import { getCommandParams } from '../helpers/utils'

export const ACTION_TYPES = {
	FETCH_SCENARIOS_INIT: 'FETCH_SCENARIOS_INIT',
	FETCH_SCENARIOS_SUCCESS: 'FETCH_SCENARIOS_SUCCESS',
	FETCH_SCENARIOS_FAIL: 'FETCH_SCENARIOS_FAIL',
	SAVE_DATAFLOW_INIT: 'SAVE_DATAFLOW_INIT',
	SAVE_DATAFLOW_SUCCESS: 'SAVE_DATAFLOW_SUCCESS',
	SAVE_DATAFLOW_FAIL: 'SAVE_DATAFLOW_FAIL',
	RESET_STATE: 'RESET_STATE',
	RESET_ERROR: 'RESET_ERROR',
	RESET_MESSAGE: 'RESET_MESSAGE'
}

export const logout = () => {
	return async (dispatch) => {
		localStorage.removeItem('app-token')
		dispatch({ type: ACTION_TYPES.RESET_STATE })
	}
}

export const fetch = token => {
	return async (dispatch) => {
		try {
			await findRelatedEntities(token, dispatch)
			localStorage.setItem('app-token', token)
		} catch (err) {
			if (err?.status === 401 || err?.response?.status === 401)
				dispatch({ type: ACTION_TYPES.FETCH_SCENARIOS_FAIL, error: 'Вы ввели неверный токен.' })
			else
				dispatch({ type: ACTION_TYPES.FETCH_SCENARIOS_FAIL, error: err?.response?.data?.message || err.message })

			localStorage.removeItem('app-token')
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_ERROR }) }, 500)
		}
	}
}

export const save = (nodes, edges, scenarios) => {
	return async (dispatch) => {
		try {
			dispatch({ type: ACTION_TYPES.SAVE_DATAFLOW_INIT })

			for (const node of nodes) {
				if (node.type === 'api') {
					let scenario = await createScenario(node.data.name || '')
					const webhooks = []
					for (const webhook of (node.data.webhooks || [])) {
						webhooks[webhooks.length] = await createWebhook(webhook, scenario.id)

						if (!scenario.parameters.hasOwnProperty('save_variables'))
							scenario.parameters.save_variables = []

						for (const result of webhook.result) {
							scenario.parameters.save_variables.push({
								...result,
								webhookId: webhooks[webhooks.length - 1].id
							})
						}
					}

					scenario = await updateScenario(scenario)

					for (const condition of (node.data.conditions || [])) {
						const webhookIdx = node.data.webhooks.findIndex(webhook => webhook.id === condition.webhookId)
						const conditionWebhookIdx = node.data.webhooks.findIndex(webhook => webhook.id === condition.conditionWebhookId)

						await createCondition({
							scenarioId: scenario.id,
							webhookId: webhookIdx > -1 ? webhooks[webhookIdx].id : null,
							conditionWebhookId: conditionWebhookIdx > -1 ? webhooks[conditionWebhookIdx].id : null,
							type: condition.type,
							mappingRules: condition.mappingRules
						})
					}

					node.data?.actions?.forEach(async (action, idx) => {
						const webhookIdx = node.data.webhooks.findIndex(webhook => webhook.id === action.webhookId)
						await createAction({
							orderNumber: idx + 1,
							webhookId: webhookIdx > -1 ? webhooks[webhookIdx].id : null,
							scenarioId: scenario.id,
							relatedWebhooks: null
						})
					})
				} else if (node.type === 'start') {
					await createScenario('init', {
						dataflow_data: { nodes, edges },
						promts: node.data.promts,
						functions: node.data.funcs.map(func => {
							const parameters = { type: 'object', required: [], properties: {} }
							func.properties.forEach(prop => {
								if (prop.required)
									parameters.required.push(prop.name)

								parameters.properties[prop.name] = {
									type: prop.type,
									format: prop.format || undefined,
									example: prop.example,
									description: prop.description
								}
							})

							return { name: func.name, description: func.description, parameters }
						})
					})
				} else if (node.type === 'function') {
					const sourceEdge = edges.find(ed => ed.target === node.id)
					const sourceNode = nodes.find(nd => nd.type === 'start')
					const targetEdge = edges.find(ed => ed.source === node.id)
					const targetNode = nodes.find(nd => nd.id === targetEdge.target)
					const func = sourceNode.data.funcs.find(f => f.id === sourceEdge?.sourceHandle)
					if (func) {
						const steps = {}
						for (const nd of nodes) {
							if (nd.type === 'checkConditions') {
								const step = nd.id === targetNode.id ? 'init' : nd.id
								const condition = nd.data.conditions.find(cond => cond.name === 'По умолчанию')
								steps[step] = {
									...getCommandParams(nd, edges),
									next: edges.find(edge => edge.sourceHandle === condition?.id)?.target || undefined
								}
								continue
							}

							if (!['api', 'start', 'function'].includes(nd.type)) {
								const step = nd.id === targetNode.id ? 'init' : nd.id
								steps[step] = {
									...getCommandParams(nd, edges),
									next: edges.find(edge => edge.source === nd.id)?.target || undefined
								}
							}
						}

						await createScenario(func.name || '', { steps })
					}
				}
			}

			for (const scenario of scenarios)
				await deleteScenario(scenario.id)

			dispatch({ type: ACTION_TYPES.SAVE_DATAFLOW_SUCCESS, message: 'Сценарий успешно сохранился в сервисе интеграций.' })
			await findRelatedEntities(localStorage.getItem('app-token'), dispatch)
		} catch (err) {
			if (err?.status === 401 || err?.response?.status === 401)
				dispatch({ type: ACTION_TYPES.SAVE_DATAFLOW_FAIL, error: 'Вы ввели неверный токен.' })
			else
				dispatch({ type: ACTION_TYPES.SAVE_DATAFLOW_FAIL, error: err?.response?.data?.message || err.message })

			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_ERROR }) }, 500)
		}
	}
}

const findRelatedEntities = async (token, dispatch) => {
	dispatch({ type: ACTION_TYPES.FETCH_SCENARIOS_INIT })
	const response = await axios({
		method: 'get',
		url: `/scenario/find-related-entities`,
		headers: { 'app-token': token }
	})

	dispatch({ type: ACTION_TYPES.FETCH_SCENARIOS_SUCCESS, data: response.data })
}

const createScenario = async (name, parameters = {}) => {
	const response = await axios({
		method: 'post',
		url: `/scenario/create`,
		headers: { 'app-token': localStorage.getItem('app-token') },
		data: {
			name,
			description: name,
			content: '',
			initialMessages: '',
			parameters,
			addMessages: true
		}
	})

	return response.data
}

const updateScenario = async data => {
	const response = await axios({
		method: 'put',
		url: `/scenario/update`,
		headers: { 'app-token': localStorage.getItem('app-token') },
		data
	})

	return response.data
}

const deleteScenario = async id => {
	const response = await axios({
		method: 'delete',
		url: `/scenario/delete?id=${id}`,
		headers: { 'app-token': localStorage.getItem('app-token') }
	})

	return response.data
}

const createWebhook = async (data, scenarioId) => {
	const body = {}
	data.body.forEach(elem => { body[elem.key] = elem.value })

	const response = await axios({
		method: 'post',
		url: `/webhook/create`,
		headers: { 'app-token': localStorage.getItem('app-token') },
		data: {
			scenarioId,
			url: data.url,
			method: data.method,
			contentType: data.contentType,
			headers: data.headers,
			cookies: data.cookies.length > 0 ? data.cookies : null,
			body,
			queryParams: data.queryParams,
			requiredEntry: data.requiredEntry,
			responseSchema: data.responseSchema ? JSON.parse(data.responseSchema) : null
		}
	})

	return response.data
}

const createCondition = async data => {
	const response = await axios({
		method: 'post',
		url: `/condition/create`,
		headers: { 'app-token': localStorage.getItem('app-token') },
		data
	})

	return response.data
}

const createAction = async data => {
	const response = await axios({
		method: 'post',
		url: `/actions/create`,
		headers: { 'app-token': localStorage.getItem('app-token') },
		data
	})

	return response.data
}