import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import {
	ReactFlow,
	ReactFlowProvider,
	applyNodeChanges,
	applyEdgeChanges,
	addEdge,
	Controls,
	Background,
	MiniMap,
	useReactFlow,
	getIncomers,
	getOutgoers,
	getConnectedEdges
} from '@xyflow/react'
import '@xyflow/react/dist/style.css'

import Navigation from './navigation'
import { Provider, useDnD } from './context'
import { StartNode } from '../nodes/start'
import ApiNode from '../nodes/api'
import CheckParamsNode from '../nodes/check-params'
import CheckConditionsNode from '../nodes/check-conditions'
import ChatAINode from '../nodes/chat-ai'
import FilterNode from '../nodes/filter'
import MapperNode from '../nodes/mapper'
import RunAPINode from '../nodes/run-api'
import ResultNode from '../nodes/result'
import SaveVariablesNode from '../nodes/save-variables'
import ScenarioNode from '../nodes/scenario'
import Settings from './settings'
import { checkEdges, checkNodes, initialData } from '../../helpers/utils'

const nodeTypes = {
	start: StartNode,
	function: ScenarioNode,
	api: ApiNode,
	checkParams: CheckParamsNode,
	runScenario: RunAPINode,
	checkConditions: CheckConditionsNode,
	mapper: MapperNode,
	filter: FilterNode,
	save_variables: SaveVariablesNode,
	getResult: ResultNode,
	chatAI: ChatAINode
}

const Dataflow = ({ scenarios, saving, error, message, toast, saveDataflow }) => {
	const [nodeId, setNodeId] = useState(null)
	const [nodes, setNodes] = useState([])
	const [edges, setEdges] = useState([])
	const reactFlowWrapper = useRef(null)
	const { screenToFlowPosition } = useReactFlow()
	const [type] = useDnD()

	useEffect(() => {
		const scenario = scenarios.findLast(sc => sc.name === 'init')
		setNodes([...(scenario?.parameters?.dataflow_data?.nodes || [])])
		setEdges([...(scenario?.parameters?.dataflow_data?.edges || [])])
	}, [scenarios])

	useEffect(() => {
		if (error)
			toast(error, { type: 'error' })
	}, [error, toast])

	useEffect(() => {
		if (message)
			toast(message, { type: 'success' })
	}, [message, toast])

	const onNodesChange = useCallback(changes => {
		setNodes(nds => applyNodeChanges(changes, nds))
	}, [setNodes])

	const onNodesDelete = useCallback(deleted => {
		setEdges(deleted.reduce((acc, node) => {
			const incomers = getIncomers(node, nodes, edges)
			const outgoers = getOutgoers(node, nodes, edges)
			const connectedEdges = getConnectedEdges([node], edges)

			return [
				...acc.filter(edge => !connectedEdges.includes(edge)),
				...incomers.flatMap(({ id: source }) =>
					outgoers.map(({ id: target }) => ({
						id: `${source}->${target}`,
						source,
						target,
					}))
				)
			]
		}, edges))
	}, [nodes, edges])

	const onEdgesChange = useCallback(changes => {
		setEdges(eds => applyEdgeChanges(changes, eds))
	}, [setEdges])

	const onDrop = useCallback(evt => {
		evt.preventDefault()
		if (!type) return

		if (type === 'start' && nodes.find(node => node.type === 'start')) {
			toast('Можно добавить только один "Стартовый сценарий"', { type: 'error' })
			return
		}

		const position = screenToFlowPosition({ x: evt.clientX, y: evt.clientY })
		setNodes(nds => nds.concat({ id: uuidv4(), type, position, data: initialData(type) }))
	}, [nodes, screenToFlowPosition, toast, type])

	const onDragOver = useCallback(evt => {
		evt.preventDefault()
		evt.dataTransfer.dropEffect = 'move'
	}, [])

	const onNodeClick = (event, node) => {
		if (['function'].includes(node?.type))
			return

		if (event.ctrlKey)
			setNodeId(node?.id)
	}

	const onConnect = useCallback(params => {
		const source = nodes.find(node => node.id === params.source)
		const target = nodes.find(node => node.id === params.target)

		const error = checkNodes(source?.type, target?.type)
		if (error) {
			toast(error, { type: 'error' })
			return
		}

		setEdges(eds => {
			const error = checkEdges(source, target, eds, params)
			if (error) {
				toast(error, { type: 'error' })
				return eds
			}

			return addEdge(params, eds)
		})
	}, [nodes, toast])

	const save = useCallback(() => {
		const startNode = nodes.find(node => node.type === 'start')
		if (!startNode) {
			toast('Вы не добавили стартовый компонент.', { type: 'error' })
			return
		}

		saveDataflow(nodes, edges, scenarios)
	}, [edges, nodes, saveDataflow, scenarios, toast])

	return (
		<Box component='main' sx={styles.main}>
			{saving && <Box sx={styles.loader}>
				<CircularProgress />
			</Box>}
			<Box sx={styles.navigation}>
				<Navigation save={save} saving={saving} />
			</Box>
			<Box sx={styles.container} ref={reactFlowWrapper}>
				<ReactFlow
					nodes={nodes}
					edges={edges}
					onNodesChange={onNodesChange}
					onNodesDelete={onNodesDelete}
					onNodeClick={onNodeClick}
					onEdgesChange={onEdgesChange}
					onConnect={onConnect}
					nodeTypes={nodeTypes}
					connectionLineStyle={styles.edge}
					fitView
					onDrop={onDrop}
					onDragOver={onDragOver}
				>
					<Controls />
					<MiniMap />
					<Background variant='dots' gap={12} size={1} />
				</ReactFlow>
			</Box>

			<Settings
				toast={toast}
				open={!!nodeId}
				node={nodes.find(n => n.id === nodeId) || null}
				nodes={nodes}
				update={node => {
					setNodes([...nodes.map(n => n.id === node.id ? node : n)])
					setNodeId(null)
				}}
				cancelEdit={() => { setNodeId(null) }}
			/>
		</Box>
	)
}

const styles = {
	main: {
		alignItems: 'start',
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',
		minHeight: '100%'
	},
	navigation: {
		position: 'fixed',
		top: 64,
		width: 200,
		height: 'calc(100vh - 64px)',
		backgroundColor: theme => theme.palette.primary.contrastText,
		borderRight: theme => `1px solid ${theme.palette.primary.border}`
	},
	container: {
		position: 'fixed',
		top: 64,
		left: 200,
		width: 'calc(100vw - 200px)',
		height: 'calc(100vh - 64px)'
	},
	edge: {
		stroke: '#212529'
	},
	loader: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'center',
		alignItems: 'center',
		position: 'fixed',
		backgroundColor: theme => `${theme.palette.primary.border}7A`,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1000
	}
}

const Flow = props => (
	<ReactFlowProvider>
		<Provider>
			<Dataflow {...props} />
		</Provider>
	</ReactFlowProvider>
)

export default Flow