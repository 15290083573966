import React, { useState } from 'react'
import { Avatar, Box, Link, List, ListItem, ListItemButton, ListItemText, Popover } from '@mui/material'

const Navbar = ({ logout }) => {
	const [anchorElUser, setAnchorElUser] = useState(null)

	return (
		<Box sx={styles.main}>
			<Link href='/' sx={styles.link}>
				<img alt='Logo' src={`/static/images/intellectdialog.png`} style={styles.logo} />
			</Link>

			<Box sx={styles.menu}>
				<Box onClick={e => { setAnchorElUser(e.currentTarget) }} sx={styles.avatarContainer}>
					<Avatar alt='Avatar' sx={styles.avatar}>I</Avatar>
				</Box>
				<Popover
					keepMounted
					sx={styles.popover}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					transformOrigin={{ vertical: 'top', horizontal: 'left' }}
					anchorEl={anchorElUser}
					open={!!anchorElUser}
					onClose={() => { setAnchorElUser(null) }}
				>
					<List sx={styles.paper}>
						<ListItem disablePadding>
							<ListItemButton onClick={logout}>
								<ListItemText primary='Выйти' />
							</ListItemButton>
						</ListItem>
					</List>
				</Popover>
			</Box>
		</Box>
	)
}

const styles = {
	main: {
		position: 'fixed',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		px: 3,
		height: 64,
		minHeight: 64,
		width: '100vw',
		backgroundColor: 'white',
		zIndex: 3,
		borderBottom: theme => `1px solid ${theme.palette.primary.border}`
	},
	link: {
		height: 64
	},
	logo: {
		height: 64,
		width: 'auto'
	},
	menu: {
		display: 'flex'
	},
	avatarContainer: {
		flexGrow: 0,
		display: 'flex',
		alignItems: 'center',
		'& :hover': {
			cursor: 'pointer'
		}
	},
	avatar: {
		bgcolor: 'primary.main'
	},
	popover: {
		mt: 1
	},
	paper: {
		width: 150
	}
}

export default Navbar