import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import { Close, Edit } from '@mui/icons-material'

import AddLine from '../custom/add-line'
import TooltipTitle from '../custom/tooltip-title'
import WebhookDialog from '../dialogs/webhook'
import ConditionDialog from '../dialogs/condition'
import Dropdown from '../custom/dropdown'

const ApiNodeSettings = ({ toast, node, update }) => {
	const [id, setId] = useState(null)
	const [name, setName] = useState('')
	const [webhooks, setWebhooks] = useState([])
	const [conditions, setConditions] = useState([])
	const [actions, setActions] = useState([])
	const [webhookAction, setWebhookAction] = useState(null)
	const [conditionAction, setConditionAction] = useState(null)

	useEffect(() => {
		if (node?.id !== id) {
			setName(node?.data?.name || '')
			setWebhooks(node?.data?.webhooks || [])
			setConditions(node?.data?.conditions || [])
			setActions(node?.data?.actions || [])
			setId(node?.id)
		}
	}, [id, node])

	const addAction = idx => {
		setActions(acs => {
			acs.splice(idx, 0, { id: uuidv4(), webhookId: '' })
			return [...acs]
		})
	}

	const editAction = (idx, key, value) => {
		setActions(acs => [...acs.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
	}

	const removeAction = idx => {
		setActions(acs => [...acs.filter((_, i) => i !== idx)])
	}

	const remove = (type, idx) => {
		if (type === 'webhook') {
			setWebhooks(whs => [...whs.filter((_, i) => i !== idx)])
		} else if (type === 'condition') {
			setConditions(cnds => [...cnds.filter((_, i) => i !== idx)])
		}
	}

	const onWebhookSave = useCallback(webhook => {
		if (webhookAction?.action === 'add') {
			setWebhooks(whs => {
				whs.splice(webhookAction.idx, 0, { id: uuidv4(), ...webhook })
				return [...whs]
			})
		} else if (webhookAction?.action === 'edit') {
			setWebhooks(whs => [...whs.map((wh, key) => key === webhookAction.idx ? ({ ...wh, ...webhook }) : wh)])
		}

		setWebhookAction(null)
	}, [webhookAction])

	const onConditionSave = useCallback(condition => {
		if (conditionAction?.action === 'add') {
			setConditions(cnds => {
				cnds.splice(conditionAction.idx, 0, { id: uuidv4(), ...condition })
				return [...cnds]
			})
		} else if (conditionAction?.action === 'edit') {
			setConditions(cnds => [...cnds.map((cnd, key) => key === conditionAction.idx ? ({ ...cnd, ...condition }) : cnd)])
		}

		setConditionAction(null)
	}, [conditionAction])

	const save = useCallback(() => {
		if (!name) {
			toast('Пожалуйста введите название', { type: 'error' })
			return
		}

		for (const condition of conditions) {
			if (!webhooks.find(w => w.id === condition.webhookId) || !webhooks.find(w => w.id === condition.conditionWebhookId)) {
				toast('Пожалуйста выберите вебхук в условии (condition)', { type: 'error' })
				return
			}
		}

		for (const action of actions) {
			if (!webhooks.find(w => w.id === action.webhookId)) {
				toast('Пожалуйста выберите вебхук в действии (action)', { type: 'error' })
				return
			}
		}

		update({ ...node, data: { ...node.data, name, webhooks, conditions, actions } })
	}, [actions, conditions, name, node, toast, update, webhooks])

	return (
		<Box sx={styles.main}>
			<Box sx={styles.buttons}>
				<Button variant='contained' color='primary' onClick={save}>Сохранить</Button>
			</Box>

			<Box sx={styles.container}>
				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Определение компонента</Typography>
				</Box>
				<Box sx={styles.row}>
					<TextField
						fullWidth
						placeholder='Название'
						value={name}
						onChange={e => setName(e.target.value)}
					/>
				</Box>

				{
					[
						{ title: 'Вебхуки (Webhooks)', type: 'webhook', items: webhooks, set: setWebhookAction },
						{ title: 'Условия (Conditions)', type: 'condition', items: conditions, set: setConditionAction }
					].map((it, key) => (
						<Items key={key} title={it.title} items={it.items} set={it.set} remove={idx => { remove(it.type, idx) }} />
					))
				}

				<Box sx={styles.rowTitle}>
					<Typography variant='h4' fontWeight='bold'>Действия (Actions)</Typography>
				</Box>
				{webhooks.length > actions.length && <Box sx={styles.row}>
					<AddLine onClick={() => { addAction(0) }} help='Добавить' />
				</Box>}
				<Box sx={styles.column}>
					{
						actions.map((action, key) => (
							<Box key={key} sx={styles.column}>
								<Box key={key} sx={styles.row}>
									<Box sx={styles.dropdown}>
										<Dropdown
											placeholder='webhookId'
											selected={action.webhookId}
											items={webhooks.map(w => ({ id: w.id, name: w.name }))}
											onChange={item => editAction(key, 'webhookId', item)}
										/>
									</Box>
									<Box sx={styles.icon}>
										<Tooltip title={<TooltipTitle title='Удалить' />} arrow>
											<IconButton aria-label='close' sx={styles.delete} onClick={() => { removeAction(key) }}>
												<Close />
											</IconButton>
										</Tooltip>
									</Box>
								</Box>
								{webhooks.length > actions.length && <Box sx={styles.row}>
									<AddLine onClick={() => { addAction(key + 1) }} help='Добавить' />
								</Box>}
							</Box>
						))
					}
				</Box>
			</Box>

			{webhookAction && (
				<WebhookDialog
					webhook={webhookAction.action === 'add' ? null : webhooks[webhookAction.idx]}
					toast={toast}
					onClose={() => { setWebhookAction(null) }}
					save={onWebhookSave}
				/>
			)}

			{conditionAction && (
				<ConditionDialog
					condition={conditionAction.action === 'add' ? null : conditions[conditionAction.idx]}
					webhooks={webhooks}
					toast={toast}
					onClose={() => { setConditionAction(null) }}
					save={onConditionSave}
				/>
			)}
		</Box>
	)
}

const Items = ({ title, items, set, remove }) => (
	<Box sx={styles.column}>
		<Box sx={styles.rowTitle}>
			<Typography variant='h4' fontWeight='bold'>{title}</Typography>
		</Box>
		<Box sx={styles.row}>
			<AddLine onClick={() => { set({ action: 'add', idx: 0 }) }} help='Добавить' />
		</Box>
		<Box sx={styles.column}>
			{
				items.map((webhook, key) => (
					<Item
						key={key}
						name={webhook.name}
						add={() => { set({ action: 'add', idx: key + 1 }) }}
						edit={() => { set({ action: 'edit', idx: key }) }}
						remove={() => { remove(key) }}
					/>
				))
			}
		</Box>
	</Box>
)

const Item = ({ name, add, edit, remove }) => (
	<Box sx={styles.column}>
		<Box sx={styles.innerRow}>
			<Box>
				<Typography variant='body1' fontWeight='bold' noWrap>{name}</Typography>
			</Box>
			<Box sx={styles.actions}>
				<Tooltip title={<TooltipTitle title='Изменить' />} arrow>
					<IconButton aria-label='close' sx={styles.edit} onClick={edit}>
						<Edit />
					</IconButton>
				</Tooltip>
				<Tooltip title={<TooltipTitle title='Удалить' />} arrow>
					<IconButton aria-label='close' sx={styles.delete} onClick={remove}>
						<Close />
					</IconButton>
				</Tooltip>
			</Box>
		</Box>

		<AddLine onClick={add} help='Добавить' />
	</Box>
)

const styles = {
	main: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	buttons: {
		width: '100%',
		display: 'flex',
		justifyContent: 'end',
		mb: 3
	},
	container: {
		px: 2,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflowX: 'hidden',
		overflowY: 'scroll'
	},
	rowTitle: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		mt: 3,
		mb: 2
	},
	innerRow: {
		width: '100%',
		px: 4,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		mb: 1
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		mb: 2
	},
	column: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	actions: {
		width: 90,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	edit: {
		color: theme => theme.palette.warning.main
	},
	delete: {
		color: theme => theme.palette.error.main
	},
	icon: {
		width: 40,
		display: 'flex',
		alignItems: 'center',
	},
	dropdown: {
		width: 'calc(100% - 56px)'
	}
}

export default ApiNodeSettings