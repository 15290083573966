import React, { useCallback } from 'react'
import { Handle, Position } from '@xyflow/react'
import { Box, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import TooltipTitle from '../custom/tooltip-title'

export const StartNode = ({ data, selected }) => {
	const theme = useTheme()

	const styles = useCallback(() => {
		return {
			main: {
				width: 150,
				backgroundColor: theme.palette.primary.main,
				border: `1px solid ${theme.palette.primary.main}`,
				borderRadius: 1,
				display: 'flex',
				flexDirection: 'column',
				boxShadow: selected ? `0px 0px 5px 3px ${theme.palette.primary.main}7a` : 'none'
			},
			title: {
				p: 0.5,
				width: '100%',
				display: 'flex',
				justifyContent: 'center'
			},
			body: {
				width: '100%',
				minHeight: 50,
				py: 2,
				backgroundColor: theme.palette.primary.contrastText,
				borderRadius: 1,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			},
			port: {
				width: 10,
				height: 10,
				border: `2px solid ${theme.palette.primary.main}`,
				backgroundColor: theme.palette.primary.contrastText
			},
			portText: {
				color: theme.palette.primary.border,
				width: 70,
				position: 'absolute',
				top: -4,
				left: -75
			}
		}
	}, [selected, theme])

	return (
		<Box sx={styles().main} onClick={() => { }}>
			<Box sx={styles().title}>
				<Typography variant='body3' fontWeight='bold' color='primary.contrastText' noWrap>Начальный сценарий</Typography>
			</Box>

			<Tooltip title={<TooltipTitle title={data.description || 'Начальный сценарий'} />} arrow>
				<Box sx={{ ...styles().body, height: ((data?.funcs?.length || 0) + 2) * 30 }} />
			</Tooltip>

			{data?.funcs?.length > 0 && data.funcs.map((func, key) => (
				<Handle
					key={key}
					type='source'
					position={Position.Right}
					id={func.id}
					style={{ ...styles().port, top: (key + 2) * 30 }}
				>
					<Typography sx={styles().portText} variant='body3' textAlign='right' noWrap>{func.name}</Typography>
				</Handle>
			))}
		</Box>
	)
}