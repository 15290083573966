import React, { forwardRef } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

import StartNodeSettings from '../nodes/start-settings'
import ApiNodeSettings from '../nodes/api-settings'
import CheckParamsNodeSettings from '../nodes/check-params-settings'
import RunAPINodeSettings from '../nodes/run-api-settings'
import CheckConditionsNodeSettings from '../nodes/check-conditions-settings'
import MapperNodeSettings from '../nodes/mapper-settings'
import FilterNodeSettings from '../nodes/filter-settings'
import SaveVariablesNodeSettings from '../nodes/save-variables-settings'
import ResultNodeSettings from '../nodes/result-settings'
import ChatAINodeSettings from '../nodes/chat-ai-settings'

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const Settings = ({ toast, open, node, nodes, update, cancelEdit }) => (
	<Dialog fullScreen open={open} TransitionComponent={Transition}>
		<DialogTitle sx={styles.title}>
			<Typography variant='title' noWrap>Изменение настроек компонента</Typography>
			<IconButton aria-label='close' onClick={cancelEdit} sx={styles.closeIcon}>
				<Close />
			</IconButton>
		</DialogTitle>
		<DialogContent dividers sx={styles.content}>
			{node?.type === 'start' && <StartNodeSettings toast={toast} node={node} update={update} />}
			{node?.type === 'api' && <ApiNodeSettings toast={toast} node={node} update={update} />}

			{node?.type === 'checkParams' && <CheckParamsNodeSettings toast={toast} node={node} start={nodes.find(nd => nd.type === 'start')} update={update} />}
			{node?.type === 'runScenario' && <RunAPINodeSettings toast={toast} node={node} api={nodes.filter(nd => nd.type === 'api')} update={update} />}
			{node?.type === 'checkConditions' && <CheckConditionsNodeSettings toast={toast} node={node} update={update} />}
			{node?.type === 'mapper' && <MapperNodeSettings toast={toast} node={node} update={update} />}
			{node?.type === 'filter' && <FilterNodeSettings toast={toast} node={node} update={update} />}
			{node?.type === 'save_variables' && <SaveVariablesNodeSettings toast={toast} node={node} update={update} />}
			{node?.type === 'getResult' && <ResultNodeSettings toast={toast} node={node} update={update} />}
			{node?.type === 'chatAI' && <ChatAINodeSettings toast={toast} node={node} update={update} />}
		</DialogContent>
	</Dialog>
)

const styles = {
	title: {
		backgroundColor: theme => theme.palette.background.default,
		m: 0,
		p: 2
	},
	closeIcon: {
		position: 'absolute',
		right: 8,
		top: 8,
		color: theme => theme.palette.text.secondary
	}
}

export default Settings