import React from 'react'
import { Box, Typography } from '@mui/material'

const TooltipTitle = ({ title }) => (
	<Box sx={styles.container}>
		<Typography color='secondary.light'>{title}</Typography>
	</Box>
)

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	buttons: {
		px: 1,
		ml: 1,
		borderRadius: '3px',
		backgroundColor: 'black'
	}
}

export default TooltipTitle